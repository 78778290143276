import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsXCircle, BsInfoCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { Formik, Field, Form } from "formik";
import { ImportantModal, SessionModal } from "./index";
import { SessionHeaderLoader } from "./loder/index";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  useGetUsers,
  useGetParticipant,
  useGetSingleSessions,
  useGetTeams,
} from "@/customHooks/index";
import { supabase } from "@/config/supabase";
import CopyButton from "./copy";
import { decrypt } from "@/utils/crypto";
import { useFetchTeamParticipant } from "@/customHooks";
import JotFormComponent from "./jotzform";
import { usePatchButtonText } from "@/customHooks/useSession";
import { constant } from "@/utils/constant";
import CountdownTimer from "./table/gameTime";

function SessionHeader({ isAscending, activeKey, guestParticipants }) {
  const location = useLocation();
  const { data: usersData } = useGetUsers();
  const { id } = useParams();
  const { data, isLoading } = useGetParticipant(Number(decrypt(id)));
  const getTeam = useGetTeams(Number(decrypt(id)));
  const getTeamParticipant = useFetchTeamParticipant(Number(decrypt(id)));
  const getSession = useGetSingleSessions(Number(decrypt(id)));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const { buttonText, setButtonText } = usePatchButtonText();
  const [orientationHovered, setOrientationHovered] = useState(false);
  const [statusBtnWidth, setStatusBtnWidth] = useState(8);

  const filterParticipant =
    getTeamParticipant.data &&
    getTeamParticipant.data.filter((item) => {
      return item.current_status > 0;
    }).length;

  const [show, setShow] = useState(false);
  const [gameLink, setGameLink] = useState(false);

  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};
  const participants = data || [];
  // Calculate unassign participant.
  const totalLengthWithNull = participants?.reduce((total, item) => {
    if (item.team_participant == null) {
      return total;
    }
    return total + item.team_participant.length;
  }, 0);
  const unassigned = Math.abs(session?.no_of_participant - totalLengthWithNull);
  // Calculate unpaid participant.
  const startGameLeave =
    getTeamParticipant.data &&
    getTeamParticipant.data.filter((item) => {
      return [3, 4, 5].includes(item.current_status);
    }).length;
  const unpaid = participants.filter((item) => item.is_paid === 0);
  const totalUnpaid =
    unpaid.length + session.no_of_participant - participants?.length;
  // Check if the session date is the same as the current date (isToday)
  const sessionDate = moment(session?.session_date);
  const currentDate = moment();
  const isToday = sessionDate.isSame(currentDate, "day");
  // Filter the 'getTeam' data to find entries with a current_status of 2
  let gameLeaveFilter =
    (getTeamParticipant?.data?.length &&
      getTeamParticipant?.data?.filter(
        (item) => item?.is_game_leave === true
      )) ||
    [];

  let gameReadyFilter =
    getTeam?.data &&
    getTeam?.data?.filter((data) => [2].includes(data?.current_status));

  let isGameReady =
    getTeam?.data && getTeam?.data?.length === gameReadyFilter?.length
      ? true
      : false;

  // Check if any entry in 'getTeam' has a 'current_status' of 3, 4, or 5
  const isGameStart = getTeam?.data?.some((data) =>
    [3, 4, 5].includes(data?.current_status)
  );

  const isOrientationTimerStart =
    getTeam?.data?.some((item) => item.current_status === 3) &&
    session?.orientation_video_start;

  const isGameComplete = getSession?.data?.session?.is_game_complete === 1;

  let isGameLinkOpen = session?.is_open_game_link;

  const handleShow = () => {
    setShow(!show);
  };

  // Functionality: Handle showing the game and starting the game
  const handleShowGame = async () => {
    if (isOrientationTimerStart) {
      try {
        await supabase
          .from("team")
          .update({
            current_status: 4,
          })
          .match({
            session_id: Number(session.id),
          });
        await supabase
          .from("team_participant")
          .update({
            current_status: 4,
          })
          .match({
            session_id: Number(session.id),
          });

        getTeam.refetch();
        getTeamParticipant.refetch();
        setOrientationHovered(false);
      } catch (error) {
        console.error("err --------> ", error);
      }
    } else {
      if (isGameReady) {
        setGameLink(!gameLink);
      } else if (isGameStart) {
        setButtonText("Game in Progress");
        setStatusBtnWidth(8);
      } else {
        setButtonText("Start Game");
        setStatusBtnWidth(14);
        await supabase
          .from("session")
          .update({ is_open_game_link: true })
          .match({ id: Number(decrypt(id)) });
        getTeam.refetch();
        getSession.refetch();
      }
    }
  };

  // Determine if the button should be disabled
  const isDisabled =
    getSession?.data?.session?.is_game_complete === 0
      ? (unassigned !== 0 || !isToday || unpaid.length !== 0) && !isGameLinkOpen
        ? true
        : buttonText === "Start Game" && !isGameReady
        ? true
        : false
      : false;

  useEffect(() => {
    if (isGameComplete) {
      setButtonText("Game complete");
      setStatusBtnWidth(10);
    } else if (isGameStart) {
      if (isOrientationTimerStart) {
        setButtonText("Orientation in progress");
      } else {
        if (
          !session?.orientation_video_start &&
          getTeam?.data?.some((item) => item.current_status === 3)
        ) {
          setStatusBtnWidth(14);
        } else if (getTeam?.data?.some((item) => item.current_status > 3)) {
          setButtonText("Game in Progress");
          setStatusBtnWidth(8);
        }
      }
    } else if (
      isGameReady ||
      (isGameLinkOpen && !isGameStart && !isGameComplete)
    ) {
      setButtonText("Start Game");
      setStatusBtnWidth(14);
    } else {
      setButtonText(constant?.openGameLink);
      setStatusBtnWidth(8);
    }
  }, [
    isGameComplete,
    isGameReady,
    isGameStart,
    isGameLinkOpen,
    isOrientationTimerStart,
  ]);

  useEffect(() => {
    supabase
      .channel("schema-db-changes")
      .on(
        "postgres_changes",
        {
          schema: "public",
          event: "UPDATE",
          table: "team",
        },
        () => {
          getTeam.refetch();
          getTeamParticipant.refetch();
        }
      )
      .on(
        "postgres_changes",
        {
          schema: "public",
          event: "UPDATE",
          table: "session",
        },
        (payload) => {
          if (payload.old.id === Number(decrypt(id))) {
            getSession.refetch();
          }
        }
      )
      .on(
        "postgres_changes",
        {
          schema: "public",
          event: "UPDATE",
          table: "team_participant",
        },
        () => {
          getTeam.refetch();
          getTeamParticipant.refetch();
        }
      )
      .subscribe();
  }, [getSession, getTeam, getTeamParticipant, id]);

  const purchaseModal = () => {
    setShowPurchaseModal(!showPurchaseModal);
  };

  return (
    <div className="bg-light py-7">
      <Container>
        {location.pathname === "/session" ? (
          <div className="hstack">
            <h1 className="text-primary text-futura-pt">
              {isAscending ? "Upcoming Sessions" : "Past Sessions"}
            </h1>
            {[1, 2].includes(usersData?.user?.role_id) && (
              <Button
                onClick={purchaseModal}
                variant="outline-primary rounded-1 py-2 ms-auto text-futura font-semibold"
              >
                Purchase Seats ({usersData?.organization?.available_seat}{" "}
                available)
              </Button>
            )}
            {showPurchaseModal && (
              <JotFormComponent
                show={showPurchaseModal}
                hide={() => purchaseModal()}
              />
            )}
          </div>
        ) : getTeam.isLoading || isLoading ? (
          <SessionHeaderLoader />
        ) : (
          <Row className="gy-6">
            <Col lg={6}>
              <h1 className="text-primary text-futura-pt lh-none">
                {session?.name}
              </h1>
              {
                <p className="text-futura-pt-book text-md">
                  Facilitated by{" "}
                  {`${session.facilitator?.first_name} ${session.facilitator?.last_name}`}{" "}
                  {session.co_facilitator
                    ? `and ${session.co_facilitator.first_name} ${session.co_facilitator.last_name}`
                    : ""}{" "}
                  | {moment(session.session_date).format("LL")}{" "}
                  {isToday && "(Today)"} | {session.no_of_participant}{" "}
                  {constant?.participants}
                </p>
              }
              {!guestParticipants && (
                <div className="hstack flex-wrap gap-4 ">
                  {gameLeaveFilter.length > 0 ? (
                    unassigned !== 0 ? (
                      <p className="text-danger hstack text-sm">
                        <BsXCircle className="me-2" />
                        {unassigned} {constant?.unassigned}{" "}
                        {unassigned === 1 ? "participant" : "participants"}
                      </p>
                    ) : (
                      <p className={`hstack text-sm text-danger`}>
                        <BsXCircle className="me-2" />
                        {gameLeaveFilter.length}{" "}
                        {gameLeaveFilter.length === 1
                          ? "participant"
                          : "participants"}{" "}
                        {constant?.left}
                      </p>
                    )
                  ) : unassigned === 0 ? (
                    !isGameComplete && (
                      <p
                        className={`hstack text-sm ${
                          !isGameReady &&
                          buttonText === "Start Game" &&
                          getTeamParticipant?.data?.length -
                            filterParticipant !==
                            0
                            ? "text-danger "
                            : "text-success "
                        }`}
                      >
                        <BsFillCheckCircleFill className="me-2" />
                        {isGameReady
                          ? activeKey === "group"
                            ? "All participants assigned"
                            : "All participants have joined"
                          : buttonText === "Start Game"
                          ? getTeamParticipant?.data?.length -
                              filterParticipant !==
                            0
                            ? `Waiting for ${
                                getTeamParticipant?.data?.length -
                                filterParticipant
                              } participants to join `
                            : "All participants have joined"
                          : isGameStart &&
                            startGameLeave === getTeamParticipant?.data?.length
                          ? "All participants are in the game"
                          : "All participants assigned"}
                      </p>
                    )
                  ) : (
                    <p className="text-danger hstack text-sm">
                      <BsXCircle className="me-2" />
                      {unassigned} {constant?.unassigned}{" "}
                      {unassigned === 1 ? "participant" : "participants"}
                    </p>
                  )}
                  {buttonText === constant?.openGameLink && (
                    <p
                      className={`text-${
                        totalUnpaid === 0 || session?.amount === 0
                          ? "success"
                          : "danger"
                      } hstack text-sm`}
                    >
                      {buttonText === constant?.openGameLink ||
                      !isGameLinkOpen ? (
                        session.payment_method === 0 ? (
                          session?.amount === 0 || totalUnpaid === 0 ? (
                            <>
                              <BsFillCheckCircleFill className="me-2" />
                              {session?.amount === 0
                                ? constant?.paidWithSeats
                                : "Invoice paid"}
                            </>
                          ) : (
                            <>
                              <BsXCircle className="me-2" />
                              {constant?.invoiceUnpaid}
                            </>
                          )
                        ) : session?.payment_method === 2 ? (
                          session?.amount === 0 || totalUnpaid === 0 ? (
                            <>
                              <BsFillCheckCircleFill className="me-2" />
                              {session?.amount === 0
                                ? constant?.paidWithSeats
                                : constant?.AllParticipantsPaid}
                            </>
                          ) : (
                            <>
                              <BsXCircle className="me-2" />
                              {totalUnpaid} {constant?.unpaidParticipants}
                            </>
                          )
                        ) : session.payment_method === 1 ? (
                          session?.amount === 0 || totalUnpaid === 0 ? (
                            <>
                              <BsFillCheckCircleFill className="me-2" />{" "}
                              {session?.amount === 0
                                ? constant?.paidWithSeats
                                : "PO accepted"}
                            </>
                          ) : (
                            <>
                              <BsXCircle className="me-2" />
                              {constant?.waitingforPO}
                            </>
                          )
                        ) : (
                          ""
                        )
                      ) : buttonText === "Start Game" &&
                        activeKey === "group" ? (
                        constant?.AllParticipantsPaid
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </div>
              )}
              {guestParticipants ? (
                <Button
                  variant={
                    session?.is_open_game_link
                      ? isGameComplete
                        ? "primary rounded-0 bg-primary border-0"
                        : buttonText
                        ? "success"
                        : isGameStart
                        ? "success rounded-0 bg-success border-0"
                        : "outline-success bg-transparent text-success"
                      : "outline-gray99 opacity-100"
                  }
                  className="rounded-0 mt-2 text-common text-futura-medium"
                  disabled={session?.is_open_game_link ? false : true}
                >
                  {session?.is_open_game_link && isGameComplete
                    ? "Game complete"
                    : isGameStart
                    ? "Game in Progress"
                    : session?.is_open_game_link
                    ? "Game Links Open"
                    : "Game Links Opening Soon"}
                </Button>
              ) : (
                <>
                  <Button
                    disabled={isDisabled}
                    variant={
                      buttonText === "Game complete"
                        ? "primary rounded-0 bg-primary border-0"
                        : (isGameReady && buttonText === "Game in Progress") ||
                          isGameStart
                        ? "success rounded-0 bg-success border-0"
                        : "secondary"
                    }
                    className={`py-3 mt-2 text-common text-futura-medium ${
                      isOrientationTimerStart
                        ? "px-5 d-flex align-items-center justify-content-center gap-2 custom-status-btn"
                        : `px-${statusBtnWidth}`
                    }`}
                    onClick={() => !isGameComplete && handleShowGame()}
                    onMouseEnter={() =>
                      isOrientationTimerStart && setOrientationHovered(true)
                    }
                    onMouseLeave={() =>
                      isOrientationTimerStart && setOrientationHovered(false)
                    }
                  >
                    {/* {orientationHovered ? "Cancel orientation?" : buttonText} */}
                    {getTeam?.data?.some(
                      (item) => item.current_status === 3
                    ) ? (
                      session?.orientation_video_start ? (
                        orientationHovered ? (
                          "Cancel orientation?"
                        ) : (
                          buttonText
                        )
                      ) : (
                        <>
                          <i className="fa fa-spinner fa-spin"></i> Orientation
                          loading
                        </>
                      )
                    ) : (
                      buttonText
                    )}
                    {isOrientationTimerStart && !orientationHovered && (
                      <CountdownTimer
                        time={session.start_date}
                        complete={!isOrientationTimerStart}
                        timeToShow={605}
                        sessionData={session}
                      />
                    )}
                  </Button>
                </>
              )}
            </Col>
            {!guestParticipants && (
              <Col lg={6} className="vstack gap-4">
                <Nav variant="tabs" className="justify-content-lg-end">
                  <Nav.Item>
                    <Nav.Link eventKey="participants">
                      {constant?.participants}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="teams">{constant?.teams}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="group">{constant?.group}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={show ? "active" : ""}
                      onClick={handleShow}
                    >
                      {constant?.setup}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="mt-auto">
                  <Formik
                    initialValues={{
                      link: "",
                    }}
                    onSubmit={async (values) => {
                      await new Promise((r) => setTimeout(r, 500));
                      alert(JSON.stringify(values, null, 2));
                    }}
                  >
                    {session.payment_method === 2 &&
                      activeKey === "participants" && (
                        <Form className="hstack gap-3">
                          <label
                            className="text-sm position-relative hstack"
                            htmlFor="email"
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {constant?.sessionHeaderTooltip}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <span
                                  {...triggerHandler}
                                  className="d-inline-flex align-items-center"
                                >
                                  <span ref={ref} className="me-2">
                                    <BsInfoCircle />
                                  </span>
                                  <span className="ms-1">
                                    {constant?.participantLink}
                                  </span>
                                </span>
                              )}
                            </OverlayTrigger>
                          </label>
                          <div className="input-group flex-1">
                            <Field
                              value={
                                session?.payment_link !== null
                                  ? session?.payment_link
                                  : ""
                              }
                              id=""
                              name="link"
                              placeholder="Allow up to 1 business day for link to appear"
                              className="form-control bg-grayish-orange text-sm"
                              disabled
                            />
                            {session?.payment_link && (
                              <CopyButton
                                session={session}
                                guestParticipants={guestParticipants}
                              />
                            )}
                          </div>
                        </Form>
                      )}
                  </Formik>
                </div>
              </Col>
            )}
          </Row>
        )}
      </Container>

      {gameLink && (
        <ImportantModal
          show={gameLink}
          session_id={Number(decrypt(id))}
          hide={() => handleShowGame()}
          setIsButtonDisabled={setIsButtonDisabled}
          isButtonDisabled={isButtonDisabled}
        />
      )}
      {show && <SessionModal id={1} show={show} hide={() => handleShow()} />}
    </div>
  );
}
export default SessionHeader;
