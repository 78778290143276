import { supabase } from "../config/supabase";

export const useFetchTeam = async (id) => {
  try {
    if (!id) return [];

    const { data: teamsData } = await supabase
      .from("team")
      .select(
        "id, name, team_index, game_start_time, created_at, current_round, current_status, is_game_complete, session_id (id, name, no_of_participant, session_date, amount, facilitator, payment_method),game_complete_status, total_pause_time, pause_date_time"
      )
      .eq("session_id", id)
      .order("team_index", { ascending: true });

    const teams = await Promise.all(
      teamsData.map(async (team) => {
        const { data: participantData } = await supabase
          .from("team_participant")
          .select(
            `id, session_id, role_id, current_status, is_game_leave, participant_id (id, email, first_name, last_name) role_id ( id, name, color )`
          )
          .eq("team_id", team.id)
          .order("role_id", { ascending: true });

        const sortedRecords = participantData.sort((a, b) => {
          const roleA = a.role_id <= 4 ? a.role_id : a.role_id - 4;
          const roleB = b.role_id <= 4 ? b.role_id : b.role_id - 4;
          return roleA - roleB;
        });
        return {
          ...team,
          session: team.session_id,
          participant: sortedRecords,
        };
      })
    );

    return teams;
  } catch (error) {
    return [];
  }
};

export const useFetchTeamParticipant = async (id) => {
  try {
    if (!id) return;
    const { data } = await supabase
      .from("team_participant")
      .select(
        "id, role_id, participant_id, team_id, session_id, current_status, is_game_leave"
      )
      .eq("session_id", id);

    return data;
  } catch (error) {}
};

export const usePatchTeam = async (teamData) => {
  try {
    const { id, name } = teamData;
    const { error } = await supabase
      .from("team")
      .update({
        name,
      })
      .eq("id", Number(id));
    if (error) {
      return error;
    }
  } catch (error) {}
};

export const usePatchAddParticipate = async (teamData) => {
  try {
    const {
      participant_id,
      role_id,
      team_id,
      session_id,
      // game_link,
      current_status,
      is_game_leave,
      handle_participant_id,
      unassigned_participant_id,
      file_maker_participant_id
    } = teamData;
    if(file_maker_participant_id){
      await updateFileMakerParticipant(file_maker_participant_id, role_id, team_id);
    }
    const { error } = await supabase
      .from("team_participant")
      .update({
        participant_id,
        // game_link,
        current_status,
        is_game_leave,
        handle_participant_id,
        unassigned_participant_id,
      })
      .match({
        session_id: Number(session_id),
        team_id: Number(team_id),
        role_id: Number(role_id),
      });

    if (error) {
      return error;
    }
    return;
  } catch (error) {}
};

export const usePatchGameParticipant = async (teamData) => {
  try {
    const {
      participant_id,
      role_id,
      team_id,
      session_id,
      round_no,
      unassigned_participant_id,
      unassigned_team_id,
      participant_id_array,
    } = teamData;
    const { data: game_data } = await supabase
      .from("games")
      .select()
      .eq("session_id", Number(session_id))
      .eq("team_id", Number(team_id))
      .eq("round_no", Number(round_no));

    const checkData = game_data.find((d) => {
      return (
        (d.unassigned_participant_id &&
          d.unassigned_participant_id === participant_id) ||
        (d.previous_participant_id_status &&
          d.previous_participant_id_status === participant_id)
      );
    });

    if (unassigned_participant_id && participant_id_array) {
      for (let item of game_data) {
        if (
          item.reassigned_modal_status.includes(role_id) &&
          participant_id_array.includes(item.participant_id)
        ) {
          const filter_ids = item.reassigned_modal_status.filter(
            (num) => num !== role_id
          );
          await supabase
            .from("games")
            .update({
              reassigned_modal_status: filter_ids,
            })
            .eq("participant_id", Number(item.participant_id))
            .eq("session_id", Number(session_id))
            .eq("team_id", Number(team_id))
            .eq("round_no", Number(round_no));
        }
      }
    } else {
      const { data } = await supabase
        .from("games")
        .select()
        .eq("unassigned_participant_id", Number(participant_id))
        .eq("role_id", Number(role_id))
        .eq("team_id", Number(team_id))
        .eq("session_id", Number(session_id));

      const departmentName =
        role_id === 1
          ? "emergencydm"
          : role_id === 2
          ? "surgerydm"
          : role_id === 3
          ? "criticalcaredm"
          : role_id === 4
          ? "stepdowndm"
          : "join";

      const url = `${
        process.env.REACT_APP_GAME_URL
      }/${departmentName}?participant_id=${Number(
        participant_id
      )}&team_id=${Number(team_id)}&session_id=${Number(
        session_id
      )}&join_as=${Number(participant_id)}&department_id=${Number(role_id)}`;

      const presenceChannel = url; // Unique channel for each link
      supabase
        .channel(presenceChannel, {
          config: {
            key: presenceChannel, // unique identifier for the link being tracked
          },
        })
        .on("presence", { event: "sync" }, (data) => {
          console.log("Presence data synchronized", data);
        })
        .on("presence", { event: "join" }, async (presenceMessage) => {
          if (data.length > 0) {
            await supabase
              .from("team_participant")
              .update({ current_status: 5 })
              .match({
                session_id: Number(session_id),
                team_id: Number(team_id),
                participant_id: Number(participant_id),
              });
          }
          return;
        })
        .on("presence", { event: "leave" }, (presenceMessage) => {
          return;
        })
        .subscribe();

      if (data.length === 0) {
        for (let item of game_data) {
          if (
            !item.reassigned_modal_status.includes(role_id) &&
            participant_id_array.includes(item.participant_id) &&
            checkData?.role_id !== item?.role_id
          ) {
            await supabase
              .from("games")
              .update({
                reassigned_modal_status: [
                  ...item.reassigned_modal_status,
                  role_id,
                ],
              })
              .eq("participant_id", Number(item.participant_id))
              .eq("session_id", Number(session_id))
              .eq("team_id", Number(team_id))
              .eq("round_no", Number(round_no));
          }
        }
      }
    }
    const { error } = await supabase
      .from("games")
      .update({
        participant_id: participant_id,
        unassigned_participant_id: unassigned_participant_id,
        unassigned_team_id: unassigned_team_id,
      })
      .match({
        session_id: Number(session_id),
        team_id: Number(team_id),
        role_id: Number(role_id),
        round_no: Number(round_no),
      });
    if (error) {
      return error;
    }
    return;
  } catch (error) {}
};

export const useDeleteTeamParticipant = async ({ id, participant_id }) => {
  try {
    if (!id) return;
    const { error } = await supabase
      .from("team_participant")
      .update({
        participant_id: null,
        current_status: 0,
        unassigned_participant_id: participant_id,
      })
      .eq("id", id);
    if (error) {
      return error;
    }
    return;
  } catch (error) {}
};
export const useFetchRole = async () => {
  try {
    const { data, error } = await supabase
      .from("role")
      .select()
      .order("id", { ascending: true });

    if (error) {
      return error;
    }
    return { roles: data };
  } catch (err) {}
};

export const addCoManager = async (data) => {
  try {
    const { role_id, team_id, session_id } = data;
    const { data: session, error: sessionErr } = await supabase
      .from("session")
      .select("no_of_participant")
      .eq("id", session_id);
    if (sessionErr) {
      return sessionErr;
    }
    const { count, error: partErr } = await supabase
      .from("team_participant")
      .select("*", { count: "exact", head: true })
      .eq("session_id", session_id);
    if (partErr) {
      return partErr;
    }

    const query = await supabase.from("team_participant").select().match({
      session_id: session_id,
      team_id: team_id,
    });
    const { data: participants, error: participantErr } = await query;
    if (participantErr) {
      return participantErr;
    }
    const teamAssignParticipantIds = participants.map((item) => item.role_id);

    if (
      session.length > 0 &&
      count < session[0].no_of_participant &&
      !teamAssignParticipantIds.includes(Number(role_id))
    ) {
      const { error } = await supabase.from("team_participant").insert({
        role_id: role_id,
        team_id: team_id,
        session_id: session_id,
      });
      if (error) {
        return error;
      }
      return;
    } else {
      return;
    }
  } catch (error) {}
};

export const RemoveCoRoles = async (data) => {
  try {
    const { role_id, team_id, session_id } = data;
    const { error } = await supabase
      .from("team_participant")
      .delete()
      .match({
        session_id: Number(session_id),
        team_id: Number(team_id),
        role_id: Number(role_id),
      });
    if (error) {
      return;
    }
    return "Delete Successfully";
  } catch (error) {}
};

const updateFileMakerParticipant = async(id, role, team) =>{
  const token = localStorage.getItem("token");
  const payload = {
    id: Number(id),
    role: role,
    team: team,
  }
  // Call the ngrok API
  const apiResponse = await fetch(
    `${process.env.REACT_APP_FILE_MACKER_PHP_API_URL}/api/update-participants`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
  );

  return await apiResponse.json();
};