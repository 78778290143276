import React, { useState } from "react";
import { Container, Navbar, Nav, Image, Dropdown } from "react-bootstrap";
import { Logo, Person } from "../assets/images/index";
import { BsPerson, BsBagCheck, BsBoxArrowRight, BsChevronDown } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useGetUsers } from "@/customHooks/index";
import { HeaderLoader } from "./loder/index";
import JotFormComponent from "./jotzform";
import { table } from "@/utils/constant";

function Header({ handleShow, handleAscending, guestParticipants }) {
  const { data, isLoading } = useGetUsers();
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const purchaseModal = () => {
    setShowPurchaseModal(!showPurchaseModal);
  };

  return (
    <>
      {isLoading && !guestParticipants ? (
        <HeaderLoader />
      ) : (
        <>
          <Navbar variant="dark" bg="primary" className="py-4">
            <Container>
              <Navbar.Brand className="py-0">
                <NavLink to="/">
                  <Image className="h-6" src={Logo} />
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="ms-auto gap-5">
                  {!guestParticipants && (
                    <>
                    <Dropdown align="end" className="my-auto">
                      <Dropdown.Toggle
                        variant="none"
                        as={Link}
                        bsPrefix="px-0 link-white text-no-decoration text-base hstack"
                      >
                       <span className="font-regular me-1">Sessions</span> <BsChevronDown className="text-sm mt-1" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleAscending && handleAscending(true)
                          }
                        >
                          {table?.upcomingSessions}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleAscending && handleAscending(false)
                          }
                        >
                          {table?.pastSessions}
                        </Dropdown.Item>
                        <Dropdown.Divider className="my-0" />
                        <Dropdown.Item
                          onClick={() => handleShow && handleShow()}
                        >
                         {table?.scheduleNewSession}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  <Dropdown align="end" className="my-auto">
                    <Dropdown.Toggle
                      variant="none"
                      as={Link}
                      bsPrefix="px-0 link-white hstack gap-1 text-no-decoration text-inter ls-widest font-semibold"
                    >
                     <Image src={Person} /> <span className="text-11 lh-snug"> {!guestParticipants && data?.organization?.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header className="text-uppercase text-danger font-semibold">
                        <BsPerson className="text-lg me-2" />{" "}
                        {data &&
                          data?.user.first_name + " " + data?.user.last_name}
                      </Dropdown.Header>
                      <Dropdown.Divider className="my-0" />
                      {([1, 2].includes(data?.user?.role_id)) && (
                        <Dropdown.Item onClick={purchaseModal}>
                          <BsBagCheck className="text-lg me-2" />
                          {table?.availableSeats} {data?.organization?.available_seat}
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={handleLogout}>
                        <BsBoxArrowRight className="text-lg me-2" />
                        {table?.logout}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  </>)}
                  {guestParticipants && (
                    <NavLink
                      to={"/"}
                      className="text-no-decoration font-semibold btn btn-outline-light py-1 px-10"
                    >
                      {table?.facilitatorLogin}
                    </NavLink>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      )}
      {showPurchaseModal && (
        <JotFormComponent
          show={showPurchaseModal}
          hide={() => purchaseModal()}
        />
      )}
    </>
  );
}
export default Header;
